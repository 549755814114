import React from 'react'
import sreenshotOne from '../../assets/images/Screenshot_1.png'
import sreenshotTwo from '../../assets/images/Screenshot_2.png'
import sreenshotThere from '../../assets/images/Screenshot_3.png'
import sreenshot4 from '../../assets/images/Screenshot_4.png'
import sreenshot5 from '../../assets/images/Screenshot_5.png'
import sreenshot6 from '../../assets/images/Screenshot_6.png'
import sreenshot7 from '../../assets/images/Screenshot_7.png'
import sreenshot8 from '../../assets/images/Screenshot_8.png'
import sreenshot9 from '../../assets/images/Screenshot_9.png'
import sreenshot11 from '../../assets/images/Screenshot_11.png'
import sreenshot12 from '../../assets/images/Screenshot_12.png'
import sreenshot13 from '../../assets/images/Screenshot_13.png'
import sreenshot14 from '../../assets/images/Screenshot_14.png'
import sreenshotMoow from '../../assets/images/MOOW.png'

const projectsInfo = [
	{
		id: 4,
		img: sreenshotMoow,
		title: 'MOOW PRO',
		text: 'MOOW is a multi-platform + CRM that combines warehouse booking, instant search for transport and' +
			' special equipment, online services in the ' +
			'business sector, and a product marketplace. Frontend - React, Redux ' +
			'toolkit, Bootstrap, MongoDB, Node.',
		link: 'https://moow.pro/'
	},
	{
		id: 2,
		img: sreenshotTwo,
		title: 'Fave Codes',
		text: 'Production project for Menu restaurants. Frontend - React, Redux toolkit, Bootstrap.',
		link: 'https://favecodes.io/'
	},
	{
		id: 3,
		img: sreenshotOne,
		title: 'ZoomTender',
		text: 'Production project for creating tenders and rates for them. – React, Redux toolkit, Bootstrap / under development ',
		link: 'https://zoomtender.com/'
	},
	{
		id: 1,
		img: sreenshotThere,
		title: 'Theke',
		text: 'Startup project for small business. React, Redux toolkit, Bootstrap, NodeJS(Express), MongoDB / under development ',
		link: 'https://theke.com.ua'
	},
	{
		id: 6,
		img: sreenshot5,
		title: 'AVE',
		text: 'Online Shop. HTML, SASS, JS',
	},
	{
		id: 7,
		img: sreenshot6,
		title: 'SMS Dashboard',
		text: 'SMS Dashboard. HTML, SASS, JS',
	},
	{
		id: 8,
		img: sreenshot7,
		title: 'Green Office',
		text: 'Info platform Green Office. HTML, SASS, JS',
	},
	{
		id: 9,
		img: sreenshot8,
		title: 'Street Life',
		text: 'Online Shop. HTML, SASS, JS',
	},
	{
		id: 10,
		img: sreenshot9,
		title: 'Squro',
		text: 'Advocacy Firm Squro. HTML, SASS, JS',
	},
	{
		id: 12,
		img: sreenshot11,
		title: 'Eva',
		text: 'Online Shop. HTML, SASS, JS',
		link: 'https://eva.ua/'
	},
	{
		id: 13,
		img: sreenshot12,
		title: 'Winehis',
		text: 'Private winery. HTML, SASS, JS',
	},
	{
		id: 14,
		img: sreenshot13,
		title: 'Wooden',
		text: 'Handmade custom furniture. HTML, SASS, JS',
	},
	{
		id: 15,
		img: sreenshot14,
		title: 'Migration Group',
		text: 'RESIDENCY-BY-INVESTMENT. HTML, SASS, JS',
		link: 'https://migrgroup.com/'
	},
	{
		id: 5,
		img: sreenshot4,
		title: 'Publishers',
		text: 'Startup project for small business. HTML, SASS, JS',
	},
]

const ExampleProjects = () => {
	return (
		<div
			className='projects'
			id='projects'
		>
			<p className='projects-p'>{'<проєкти>'}</p >
			<div className='projects__wrapper'>
				{
					projectsInfo.map((item) =>
						<div
							className='projects__box'
							key={item.id}
						>
							<img
								src={item.img}
								alt={item.title}
								className='projects__box-image'
							/>
							<div className='projects__box-content'>
								<h3 className='projects__box-title'>
									{item.title}
								</h3 >
								<p className='projects__box-text'>
									{item.text}
								</p >
								{
									item?.link && <a
										className='projects__box-link'
										href={item?.link}
										target={"_blank"}
									>
										Переглянути сайт =>
									</a >
								}
							</div >
						</div >
					)
				}
			</div >
			<p className='projects__wrapper-dots'>...</p >
			<p className='projects-p'>{'<проєкти>'}</p >
		</div >
	)
}

export default ExampleProjects