import AboutMe from './components/aboutMe/AboutMe'
import Footer from './components/footer/Footer'
import HeaderDesktop from './components/header/HeaderDesktop'
import ExampleProjects from './components/projects/ExampleProjects'
import Slogan from './components/slogan/Slogan'
import SloganTwo from './components/sloganTwo/SloganTwo'

function App() {
	return (
		<div className='App'>
			<HeaderDesktop />
			<Slogan />
			<AboutMe />
			<ExampleProjects />
			<SloganTwo />
			<Footer />
    </div >
	)
}

export default App
