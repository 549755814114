import React from 'react'

const Square = () => {
	return (
		<>
			<div className="back side">React</div>
			<div className="left side">Frontend</div>
			<div className="right side">Backend</div>
			<div className="top side">Java Script</div>
			<div className="bottom side">Redux</div>
			<div className="front side">Bootstrap</div>
		</>
	)
}
export default Square